import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const API_BASIC = apiPath.basics;
const API_AUTHORIZATION = apiPath.authorization;
// 应急组织      树列表
export const getEmergencyOrgData = params => {
  return axios.get(API_BASIC + "/list/emergency-group-tree", { params });
};

// 应急人员 列表
export const getAddressBooks = params => {
  return axios.get(API_BASIC + "/list/emergency-user/address-books", {
    params
  });
};

// // 应急人员 列表 详情
// export const getEmergencyUserInfo = id => {
//   return axios.get(`${API_BASIC}/detail/emergency-user/${id}`);
// };

// 应急预案 列表
export const pagePlannings = params => {
  return axios.get(`${API_BASIC}/page/plannings`, { params });
};
// 应急预案 列表 详情
export const planningsDetail = params => {
  return axios.get(`${API_BASIC}/info/plannings/${params.id}`);
};
// 应急预案 列表 详情
export const planningLevels = params => {
  return axios.get(`${API_BASIC}/select/planning_levels`);
};
// 应急预案 列表 详情
export const planningTypes = params => {
  return axios.get(`${API_BASIC}/select/planning_types`);
};
// 应急预案 列表 详情
export const planningStatus = params => {
  return axios.get(`${API_BASIC}/select/planning_status`);
};
// 应急预案 列表 详情
export const treeDept = params => {
  return axios.get(`${API_AUTHORIZATION}/select/tree/dept`, { params });
};
