<template>
  <div class="plan">
    <div class="plan-top">
      <van-nav-bar
        title="应急预案"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
      </van-nav-bar>
      <div ref="filterBoxWrapper" class="filter-box-wrapper">
        <van-search
          v-model="query.name"
          placeholder="请输入预案名称关键字"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(index, item)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="plan-total">共{{ total }}个报警</div>
        </div>
      </div>
    </div>

    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="plan-list"
      @refresh="onRefresh"
      @scroll.native="onScroll"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="item in planList"
          :key="item.id"
          class="plan-item"
          @click="gotoPlanDetail(item.id)"
        >
          <div class="plan-header">
            <div class="plan-title">
              <div class="plan-name-box">
                <span class="plan-name">{{ item.name }}</span>
                <span class="plan-tag">{{ item.levelName }}</span>
              </div>
              <div class="plan-time">
                <span>
                  <img
                    class="second-icon"
                    src="../../../assets/images/workticket-item-1.png"
                  />
                  <span class="date-text">{{ item.orgName }}</span>
                </span>
                <span>
                  <!-- <img class="second-icon" src="../../../assets/images/time.png" /> -->
                  <span
                    class="iconfont"
                    style="font-size: 14px;margin-right: 4px;"
                    >&#xe681;</span
                  >
                  <span class="date-text">{{ item.typeName }}</span>
                </span>
              </div>
            </div>
            <div class="plan-status">
              <!-- <van-icon
                :name="disposalStatusImgSrcSwitch(item.planningStatus)"
                class-prefix="iconfont"
                class="trouble-status-icon"
              /> -->
              <img class="" :src="disposalSrc(item.planningStatus)" />
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="depVisible" round position="bottom">
      <van-cascader
        v-model="popupVisibleObj[activeIndex].value"
        title="所属部门"
        :field-names="fieldNames"
        :options="popupVisibleObj[activeIndex].list"
        @close="depVisible = false"
        @finish="onFinish"
      >
      </van-cascader>
      <!-- <van-button round class="reset-btn" @click="onClear">重置</van-button> -->
      <van-button class="clear-btn" round @click.prevent="onClear"
        >重置</van-button
      >
    </van-popup>
    <SelectPopupGrade
      ref="selectPopupGrade1"
      v-model="popupVisibleObj[activeIndex].value"
      :immediately="false"
      :filterable="false"
      list-key="value"
      :title="popupVisibleObj[activeIndex].title"
      :list.sync="popupVisibleObj[activeIndex].list"
      :visible="visible"
      @change="v => popupGradeChange(v, activeIndex)"
      @close="visible = false"
    />
  </div>
</template>

<script>
import {
  pagePlannings,
  planningLevels,
  planningTypes,
  planningStatus,
  treeDept
} from "@/api/urgent";
import { parseTime } from "@/utils";
// import CascadeDepartment from "./depPopup.vue";
import { mapState, mapMutations } from "vuex";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import yshwba from "../../../assets/images/yshwba.png";
import dsh from "../../../assets/images/dsh.png";
import ytj from "../../../assets/images/ytj.png";
import doneUi from "../../../assets/images/doneUi.png";
export default {
  name: "Plan",
  components: {
    SelectPopupGrade
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      scTop: 0,
      departmentIds: [],
      filterList: [
        {
          name: "预案级别",
          type: "select",
          text: "",
          isActive: false
        },
        {
          name: "所属部门",
          text: "",
          type: "select",
          isActive: false
        },
        {
          name: "预案类型",
          type: "select",
          isActive: false
        },
        {
          name: "审核状态",
          type: "select",
          text: "",
          isActive: false
        }
      ],
      activeIndex: 0,
      visible: false,
      fieldNames: {
        text: "label",
        value: "id",
        children: "children"
      },
      depVisible: false,
      popupVisibleObj: [
        {
          title: "预案级别",
          value: "",
          list: []
        },
        {
          title: "所属部门",
          value: "",
          list: []
        },
        {
          title: "预案类型",
          value: "",
          list: []
        },
        {
          title: "审核状态",
          value: "",
          list: []
        }
      ],
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      // 查询条件
      query: {
        size: 10,
        page: 1,
        name: "",
        orgCode: "", // 所属单位
        sort: "",
        userId: "",
        levelId: "",
        typeId: "",
        planningStatus: "",
        isNotPlanningStatus: "",
        department: ""
      },
      // 告警列表
      planList: [],
      total: 0
    };
  },
  async created() {
    this.scrollFrom = "list";
    this.lastScrollTop = 0;
    // this.query.orgCode = this.userInfo.orgCode;
    this.getSelect();
    this.query = {
      size: 10,
      page: 1,
      name: "",
      orgCode: "", // 所属单位
      sort: "",
      userId: "",
      levelId: "",
      typeId: "",
      planningStatus: "",
      isNotPlanningStatus: "",
      department: ""
    };
    // const temp = sessionStorage.getItem("queryPlanDetails");
    // this.query = temp && { ...JSON.parse(temp), page: 1 };
    // // console.log(this.query)
    // this.popupVisibleObj[0].value = this.query.levelId || "";
    // this.popupVisibleObj[1].value = this.query.department || "";
    // this.popupVisibleObj[2].value = this.query.typeId || "";
    // this.popupVisibleObj[3].value = this.query.planningStatus || "";
    // sessionStorage.setItem("queryPlanDetails", "");
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    disposalSrc(index) {
      const obj = {
        0: yshwba,
        1: dsh,
        2: ytj,
        3: doneUi
      };
      return obj[index];
    },
    popupGradeChange(v, index) {
      this.popupVisibleObj[index].value = v[0] ? v[0].value : "";
      if (index === 0) {
        this.query.levelId = v[0] ? v[0].value : "";
      }
      if (index === 2) {
        this.query.typeId = v[0] ? v[0].value : "";
      }
      if (index === 3) {
        this.query.planningStatus = v[0] ? v[0].value : "";
      }
      this.onSearch();
    },
    onFinish({ selectedOptions }) {
      console.log(this);
      const temp = selectedOptions.map(option => option.id);
      this.query.department = temp.join(",");
      this.popupVisibleObj[this.activeIndex].value = this.query.department;
      this.onSearch();
      this.depVisible = false;
    },
    onClear() {
      this.query.department = "";
      this.onSearch();
      this.depVisible = false;
    },
    onScroll(e) {
      if (this.scrollFrom === "detail") {
        this.scrollFrom = "list";
        return;
      }
      const dom = e.target;
      const offsetT = this.lastScrollTop - dom.scrollTop;
      const isTop = offsetT < 0;
      let v = this.scTop;
      if (isTop) {
        // 向上滚动
        if (Math.abs(offsetT) >= 52) {
          this.scTop = -104;
        }
      } else {
        this.scTop = 0;
      }
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.lastScrollTop = dom.scrollTop;
      }, 20);
    },
    async getSelect() {
      planningLevels().then(res => {
        this.popupVisibleObj[0].list = [...res];
      });
      treeDept({ orgCode: this.userInfo.orgCode }).then(res => {
        this.popupVisibleObj[1].list = res;
      });
      planningTypes().then(res => {
        this.popupVisibleObj[2].list = res;
      });
      planningStatus().then(res => {
        this.popupVisibleObj[3].list = res;
      });
    },
    onLoad() {
      if (this.refreshing) {
        this.planList = [];
        this.refreshing = false;
      }
      this.getList();
    },
    selectCon(index) {
      this.activeIndex = index;
      if (index === 1) {
        this.depVisible = true;
      } else {
        this.visible = true;
      }
    },
    async getList() {
      this.finished = false;
      // const { id } = this.$route.query;
      // const q = { ...this.query };
      // console.log(this.query)
      pagePlannings({ ...this.query })
        .then(data => {
          this.loading = false;
          if (data.list && data.list.length) {
            this.planList = this.planList.concat(data.list);
            this.query.page++;
          } else {
            this.finished = true;
          }
          this.total = data.total;
        })
        .catch(err => {
          this.loading = false;
          this.finished = false;
          this.loadError = true;
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    gotoPlanDetail(id) {
      this.$router.push({ name: "PlanDetails", params: { id } });
      // sessionStorage.setItem("queryPlanDetails", JSON.stringify(this.query));
    },
    onSearch() {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.planList = [];
      this.getList();
    },
    onCancel() {
      this.finished = false;
      this.handleCondition();
    },
    onClickLeft() {
      // if (this.popupVisibleObj.some(item => item.value) || this.query.name) {
      //   this.query = {
      //     size: 10,
      //     page: 1,
      //     name: "",
      //     orgCode: "", // 所属单位
      //     sort: "",
      //     userId: "",
      //     levelId: "",
      //     typeId: "",
      //     planningStatus: "",
      //     isNotPlanningStatus: "",
      //     department: ""
      //   };
      //   this.popupVisibleObj[0].value = "";
      //   this.popupVisibleObj[1].value = "";
      //   this.popupVisibleObj[2].value = "";
      //   this.popupVisibleObj[3].value = "";
      //   this.onSearch();
      //   return;
      // }
      history.go(-1);
    },
    /**
     * 筛选条件确认调用
     * @returns {Promise<void>}
     */
    async handleCondition() {
      this.query.page = 1;
      // this.planList = [];
      this.loading = true;
      this.getList();
    }
  },
  activated() {
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
    if (this.needRefreshList) {
      this.onSearch();
      this.setNeedRefreshList({ value: false });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "AlarmDetail") {
        vm.scrollFrom = "detail";
      } else {
        vm.scrollFrom = "list";
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    next();
  }
};
</script>

<style scoped lang="scss">
.plan {
  font-family: PingFangSC;
  position: relative;
  text-align: left;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .dept-item {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 14px;
    &.active {
      .dept-name {
        color: $--color-primary;
      }
    }
    &__left {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }
    .dept-name {
      display: inline-block;
      color: #2e2e4d;
      max-width: 100px;
    }
    .iconfont-danxuan-weixuan {
      color: #d4dbe3;
    }
  }
}
.plan-top {
  position: relative;
  .van-nav-bar {
    z-index: 12;
  }
  .search-bar {
    padding-bottom: 0;
  }
  .filter-box-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s;
    z-index: 10;
  }
  .filter-box {
    padding: 5px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #e1e3e8;
    &::-webkit-scrollbar {
      display: none;
    }
    .filter-item {
      display: inline-block;
      // border-radius: 10px;
      // background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;
      &.select {
        position: relative;
        padding-right: 18px;
        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }
      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .search-condition {
    display: flex;
    // margin: 1vw 5.6vw 3vw 5.6vw;
    justify-content: center;
    .loading {
      display: inline-block;
    }
    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }
    .plan-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      height: 13px;
      padding: 12px 0;
    }
    .condition-switch {
      color: $--color-icon;
      i {
        font-size: 5vw;
      }
    }
  }
}
.plan-search {
  // position: relative;
  margin: 0;
  // height: 14vh;
  .date-time-box {
    padding: 12px 16px 0 16px;
    background-color: #fff;
    .datetime {
      position: relative;
      margin: 0;
    }
  }
}
.plan-list {
  /*height: 68vh;*/
  padding-top: 110px;
  flex: 1;
  overflow-y: auto;
  z-index: 9;
}
// .plan-item:nth-of-type(1) {
//   margin-top: 0;
// }
.plan-item {
  // margin: 5.6vw;
  // border-bottom: 1px solid rgba(235, 235, 235, 1);
  // padding-bottom: 2vh;
  padding: 11px 17px 13px 16px;
  border-bottom: 8px solid #eceef2;
  font-size: 4vw;
  .plan-header {
    display: flex;
    .plan-title {
      flex: 3;
      max-width: 70vw;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.1;
      }
      color: #3b4664;
      .plan-name-box {
        margin-bottom: 10pt;
        .iconfont-baojingyuzhi {
          margin: 0 4px 0 0;
          vertical-align: sub;
        }
        font-family: PingFangSC;
        .plan-name {
          height: 20px;
          font-size: 14px;
          font-weight: bold;
          color: #3b4664;
          line-height: 20px;
          font-size: 14px;
        }
        .plan-tag {
          font-size: 11px;
          color: #fff;
          height: 16px;
          line-height: 16px;
          width: 44px;
          background-color: #f55f5f;
          border-radius: 2px;
          margin-left: 6px;
          padding: 0 4px;
        }
      }
      .status-box {
        padding: 0 0 0 3px;
        margin: 0 0 6px;
        .equipment-name {
          font-size: 12px;
          font-weight: 400;
          color: #3b4664;
          line-height: 17px;
          margin: 0 20px 0 0;
        }
        .status-color-box {
          display: inline-block;
          font-size: 11px;
          font-weight: 400;
          color: #8b4fee;
          line-height: 16px;
          padding: 0 6px 0;
          margin: 0 8px 0 0;
          background: #ede2ff;
          border: 1px solid #8b4fee;
          border-radius: 3px;
        }
        .type-color {
          color: #00b6d6;
          background: #d8feff;
          border: 1px solid #00b6d6;
        }
      }
      .second-icon {
        margin: 0 4px 0 0;
        vertical-align: middle;
      }
    }
    .plan-status {
      flex: 1;
      /*font-size: 100vw;*/
      text-align: right;
      padding: 7px 0 0;
      > img {
        width: 44px;
      }
    }
  }
  .plan-time {
    color: #a0a2a6;
    padding: 0 0 0 3px;
    .second-icon {
      margin: 0 4px 0 0;
      vertical-align: middle;
    }
    .date-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin: 0 20px 0 0;
    }
  }
}
.second-icon {
  width: 13px;
}
.clear-btn {
  height: 38px;
  width: 83px;
  margin-left: 50%;
  margin-bottom: 10px;
  transform: translateX(-50%);
}
</style>
